import React, { useEffect, useState } from "react";
import { Button, Grid, Stack, TextField, Typography } from "@mui/material";
import DirectionsCarIcon from "@mui/icons-material/DirectionsCarTwoTone";
import { Add } from "@mui/icons-material";
// import { debounce } from "lodash";
import {
  //useEditLenderMutation,
  useUpdateLenderArrayFieldsMutation,
} from "../../../../store/slices/apiSlice";

const InformationSection = React.memo(({ lender }) => {
  // setLender
  // const [editLender] = useEditLenderMutation();
  const [updateLenderArrayFields] = useUpdateLenderArrayFieldsMutation();
  const [values, setValues] = useState([...lender.info]);

  useEffect(() => {
    setValues([...lender.info]);
  }, [lender.info]);

  const lenderId = lender?._id;
  const handleRemoveInfo = async (index) => {
    const updatedValues = values.filter((_, pos) => pos !== index);
    setValues(updatedValues);

    await updateLenderArrayFields({ lenderId, info: updatedValues });
  };

  const handleAddInfo = async () => {
    const updatedInfo = [...values, ""];
    setValues(updatedInfo);
    await updateLenderArrayFields({ lenderId, info: updatedInfo });
  };

  const handleEditInfo = async (e, index) => {
    const updatedValues = [...values];
    updatedValues[index] = e.target.value;
    setValues(updatedValues);

    // Only call the API if the new value is different from the original
    if (updatedValues[index] !== lender.info[index]) {
      await updateLenderArrayFields({ lenderId, info: updatedValues });
    }
  };

  return (
    <Grid
      container
      xs={12}
      style={{
        margin: "0 0 30px",
        padding: "0 0 20px",
        borderBottom: "1px solid rgba(0,0,0,0.12)",
      }}
    >
      <Grid container xs={3} alignItems="start" direction="column">
        <Stack direction="row" style={{ marginBottom: "10px" }}>
          <DirectionsCarIcon style={{ marginRight: "10px" }} />
          <Typography>Information Needed</Typography>
        </Stack>
      </Grid>
      <Grid
        container
        xs={9}
        spacing={2}
        alignContent={"center"}
      >
        <Grid
          container
          item
          spacing={1}
          xs={9}
          direction="column"
          style={{ margin: "0px 0px 7px 0px" }}
          rowGap={"16px"}
        >
          {values.map((info, index) => (
            <Grid item key={index}>
              <Stack direction={"row"} alignItems={"center"} gap={"16px"}>
                <TextField
                  fullWidth
                  size="small"
                  value={info}
                  label={`Info #${index + 1}`}
                  onBlur={(e) => handleEditInfo(e, index)}
                  onChange={(e) => {
                    const updatedValues = [...values];
                    updatedValues[index] = e.target.value;
                    setValues(updatedValues);
                  }}
                />
                <Button variant="outlined" size="small" onClick={() => handleRemoveInfo(index)}>
                  REMOVE
                </Button>
              </Stack>
            </Grid>
          ))}
          <Button sx={{ width: "200px", ml: "8px" }} variant="contained" startIcon={<Add />} onClick={handleAddInfo}>
            ADD INFORMATION
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
});

export default InformationSection;
