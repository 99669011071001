import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Provider } from "react-redux";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
// import io from "socket.io-client";
import { createTheme, ThemeProvider, StyledEngineProvider, responsiveFontSizes } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import { Alert, Snackbar } from "@mui/material";
import { PersistGate } from "redux-persist/integration/react";
import { SnackbarProvider } from "notistack";
import { store, persistor } from "./store";
import "react-quill/dist/quill.snow.css";
// import { store } from "./store";
// routes
import Router from "./routes";
// theme
// components

// import colours from "./style/color";

// ----------------------------------------------------------------------
let theme = createTheme({
  palette: {
    type: "light",
    primary: {
      main: "#6A0BE3",
    },
    secondary: {
      main: "#111111",
    },
    background: {
      default: "rgb(251, 251, 251)",
      paper: "#ffffff",
    },
  },

  typography: {
    body1: {
      letterSpacing: "0.5px",
    },
    h1: {
      fontFamily: [
        "Cabin",
        "-apple-system",
        "BlinkMacSystemFont",
        '"Segoe UI"',
        "Roboto",
        '"Helvetica Neue"',
        "Arial",
        "sans-serif",
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(","),
    },
    h2: {
      fontFamily: [
        "Cabin",
        "-apple-system",
        "BlinkMacSystemFont",
        '"Segoe UI"',
        "Roboto",
        '"Helvetica Neue"',
        "Arial",
        "sans-serif",
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(","),
    },
    h3: {
      fontFamily: [
        "Cabin",
        "-apple-system",
        "BlinkMacSystemFont",
        '"Segoe UI"',
        "Roboto",
        '"Helvetica Neue"',
        "Arial",
        "sans-serif",
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(","),
    },
    h4: {
      fontFamily: [
        "Cabin",
        "-apple-system",
        "BlinkMacSystemFont",
        '"Segoe UI"',
        "Roboto",
        '"Helvetica Neue"',
        "Arial",
        "sans-serif",
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(","),
    },
    h5: {
      fontFamily: [
        "Cabin",
        "-apple-system",
        "BlinkMacSystemFont",
        '"Segoe UI"',
        "Roboto",
        '"Helvetica Neue"',
        "Arial",
        "sans-serif",
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(","),
    },
    h6: {
      fontFamily: [
        "Cabin",
        "-apple-system",
        "BlinkMacSystemFont",
        '"Segoe UI"',
        "Roboto",
        '"Helvetica Neue"',
        "Arial",
        "sans-serif",
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(","),
    },
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
  },
  // buttons: {
  //   fontFamily: [
  //     "Cabin",
  //     "-apple-system",
  //     "BlinkMacSystemFont",
  //     '"Segoe UI"',
  //     "Roboto",
  //     '"Helvetica Neue"',
  //     "Arial",
  //     "sans-serif",
  //     '"Apple Color Emoji"',
  //     '"Segoe UI Emoji"',
  //     '"Segoe UI Symbol"',
  //   ].join(","),
  // },
  components: {
    MuiAccordion: {
      styleOverrides: {
        root: {
          boxShadow: "none",
          background: "none",
        },
      },
    },
    MuiAccordionSummary: {
      styleOverrides: {
        root: {
          "&$expanded": {
            minHeight: 0,
          },
          background: "#f3f3f3",
          margin: 0,
        },
        expanded: {},
        content: {
          "&$expanded": {
            margin: 0,
            minHeight: 0,
          },
        },
      },
    },
    // MuiTextField: {
    //   styleOverrides: {
    //     root: {
    //       borderWidth: "2px",
    //     },
    //   },
    // },
    // MuiButton: {
    //   styleOverrides: {
    //     root: {
    //       textTransform: "lowercase",
    //       fontWeight: 500,
    //       fontSize: "1rem",
    //       letterSpacing: "0.1px"
    //     },
    //   },
    // },
  },
});

theme = responsiveFontSizes(theme);

export default function App() {
  const tokenExpired = localStorage.getItem("tokenExpired");
  const navigate = useNavigate();
  // const [socket, setSocket] = useState(null);

  // useEffect(() => {
  // const newSocket = io(process.env.REACT_APP_API_URL, {
  //   transports: ["websocket"],
  // });
  // setSocket(newSocket);
  // return () => newSocket.close();
  // }, [setSocket]);

  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (tokenExpired) {
      setOpen(true);
      localStorage.removeItem("token");
      navigate("/login");
    }
  }, [tokenExpired]);

  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <StyledEngineProvider injectFirst>
          <ThemeProvider theme={theme}>
            <SnackbarProvider maxSnack={3} style={{ height: "150px" }}>
              <CssBaseline />
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <Router
                //  socket={socket}
                />
                {tokenExpired ? (
                  <Snackbar
                    open={open}
                    autoHideDuration={8000}
                    anchorOrigin={{ vertical: "top", horizontal: "center" }}
                    onClose={() => setOpen(false)}
                  >
                    <Alert elevation={6} severity="error" sx={{ width: "100%" }} onClose={() => setOpen(false)}>
                      Your current login session has expired. Please login again to continue.
                    </Alert>
                  </Snackbar>
                ) : null}
              </LocalizationProvider>
            </SnackbarProvider>
          </ThemeProvider>
        </StyledEngineProvider>
      </PersistGate>
    </Provider>
  );
}
