import React, { useEffect, useState } from "react";
import ReactQuill from "react-quill";
import { Button, Grid, MenuItem, Stack, TextField, Typography } from "@mui/material";
import DirectionsCarIcon from "@mui/icons-material/DirectionsCarTwoTone";
import { Add } from "@mui/icons-material";
import { SUBMISSION_STEPS, SUBMISSION_STEPS_TYPES } from "../../../../constants";
import {
  useUpdateLenderArrayFieldsMutation,
} from "../../../../store/slices/apiSlice";
import "./basicDetails.css";

const SubmissionStepsSection = React.memo(({ lender }) => {
  const [updateLenderArrayFields] = useUpdateLenderArrayFieldsMutation();
  const [values, setValues] = useState([...lender.submissionSteps]);
  const lenderId = lender?._id;
  const usedSubmissionStepsTypes = values?.map((step) => step.type)
  const currentStepNames = new Set(usedSubmissionStepsTypes);
  const isAllStepExist = Object.keys(SUBMISSION_STEPS).every((step) => currentStepNames.has(step))
  const filterStepsList = Object.keys(SUBMISSION_STEPS).filter(
    (step) => !usedSubmissionStepsTypes.includes(step)
  );

  useEffect(() => {
    setValues([...lender.submissionSteps]);
  }, [lender.submissionSteps]);

  const handle = {
    onChangeField: async (key, value, id) => {
      const updatedValues = values.map(element => {
        if (element.type === id) {
          if (key === "type") {
            return {
              ...element,
              ...SUBMISSION_STEPS[value]
            }
          } else {
            return {
              ...element,
              [key]: value
            }
          }
        } else {
          return element
        }
      });
      setValues(updatedValues);

      if (key === "type") {
        await updateLenderArrayFields({ lenderId, submissionSteps: updatedValues });
      }

    },
    handleAddStep: async () => {
      if (filterStepsList?.length <= 0) return

      const nextStepToAdd = filterStepsList[0]
      const submissionData = SUBMISSION_STEPS[nextStepToAdd]

      const newStep = {
        type: submissionData?.type,
        label: submissionData?.label,
        body: submissionData?.body || "",
      }

      let updatedSteps = [...values];
      updatedSteps.push(newStep)
      setValues(updatedSteps);
      await updateLenderArrayFields({ lenderId, submissionSteps: updatedSteps });
    },
    handleRemoveStep: async (stepType) => {
      const updatedValues = values.filter((pos) => pos.type !== stepType);
      setValues(updatedValues);
      await updateLenderArrayFields({ lenderId, submissionSteps: updatedValues });
    },
    onBlurEditorTextUpdate: async () => {
      await updateLenderArrayFields({ lenderId, submissionSteps: values });
    }
  }

  return (
    <Grid
      container
      xs={12}
      style={{
        margin: "0 0 30px",
        padding: "0 0 20px",
        borderBottom: "1px solid rgba(0,0,0,0.12)",
      }}
    >
      <Grid container xs={3} alignItems="start" direction="column">
        <Stack direction="row" style={{ marginBottom: "10px" }}>
          <DirectionsCarIcon style={{ marginRight: "10px" }} />
          <Typography>Submission Steps</Typography>
        </Stack>
      </Grid>
      <Grid
        container
        xs={9}
        spacing={2}
        style={{
          padding: "0 0 0 15px",
        }}
        alignContent={"center"}
      >
        <Grid
          container
          spacing={1}
          xs={12}
          sm={12}
          direction="row"
          rowGap={"16px"}
        >
          <Grid item xs={12} sm={12}>
            {values?.map((step, index) => (
              <Stack key={index} spacing={1} mt={2.5}>
                <Stack
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    gap: "5px"
                  }}
                >
                  <Grid item xs={0.5} sm={0.5}>
                    <Typography variant="h6">{index + 1}.</Typography>
                  </Grid>
                  <Stack
                    style={{
                      width: "100%",
                      display: "flex",
                      flexDirection: "column",
                      gap: "10px"
                    }}
                  >
                    <Stack
                      style={{
                        flexDirection: "row",
                        gap: "20px"
                      }}
                    >
                      <Grid item xs={3} sm={3}>
                        <TextField
                          fullWidth
                          size="small"
                          select
                          value={step.type}
                          label="Type"
                          onChange={(e) => {
                            handle.onChangeField("type", e.target.value, step.type)
                          }}
                        >
                          {Object.keys(SUBMISSION_STEPS_TYPES)?.map((option, index) => (
                            <MenuItem key={index} value={option} disabled={!filterStepsList?.includes(option)}>
                              {option}
                            </MenuItem>
                          ))}
                        </TextField>
                      </Grid>
                      <Grid item xs={1} sm={1}>
                        <Button variant="outlined" size="small" onClick={() => handle.handleRemoveStep(step.type)}>
                          REMOVE
                        </Button>
                      </Grid>
                    </Stack>

                    {step.type === SUBMISSION_STEPS_TYPES.acknowledgement && (
                      <Grid item xs={11} sm={11}>
                        <ReactQuill
                          value={step.body}
                          onChange={(value) => {
                            handle.onChangeField("body", value, step.type)
                          }}
                          onBlur={() => {
                            handle.onBlurEditorTextUpdate()
                          }}
                        />
                      </Grid>
                    )}
                  </Stack>
                </Stack>
              </Stack>
            ))}
          </Grid>

          {!isAllStepExist && (
            <Grid item xs={12} sm={12} style={{ margin: "0 0 0 7px" }}>
              <Button sx={{ width: "200px" }} variant="contained" startIcon={<Add />} onClick={handle.handleAddStep}>
                ADD STEPS
              </Button>
            </Grid>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
});

export default SubmissionStepsSection;