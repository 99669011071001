import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import { Grid, Stack, TextField, Typography } from "@mui/material";
import DirectionsCarIcon from "@mui/icons-material/DirectionsCarTwoTone";
import * as Yup from "yup";
// import { debounce } from "lodash";
import moment from "moment";
import { useEditLenderMutation, useUpdateLenderArrayFieldsMutation } from "../../../../store/slices/apiSlice";
import "./basicDetails.css";
// import regex from "../../../../../src/utils/regex";
import { fieldRequiredMessage } from "../../../../../src/constants";

const BasicDetailSection = React.memo(({ lender }) => { // setLender
  const lenderId = lender?._id;
  const [editLender] = useEditLenderMutation();
  const [updateLenderArrayFields] = useUpdateLenderArrayFieldsMutation();

  const [allValues, setAllValue] = useState({
    lender: lender?.lender
  });
  const [fieldErrors, setFieldErrors] = useState({
    lender: "",
  });

  const [logoUrlValue, setLogoUrlValue] = useState(lender?.logoURL);

  useEffect(() => {
    setLogoUrlValue(lender?.logoURL);

    if (lender?.lender) {
      setAllValue({
        lender: lender?.lender
      })
    }
  }, [lender.logoURL]);

  const validationSchema = Yup.object({
    lender: Yup.string()
      .required(fieldRequiredMessage.lender)
      // .matches(regex.nameWithNumberRegex, "Only characters, letters and space allowed.")
      .min(2, "Maximum of 2 characters")
      .max(50, "Maximum of 50 characters"),
  })

  const validateField = async (fieldName, value) => {
    try {
      await validationSchema.validateAt(fieldName, { [fieldName]: value });
      setFieldErrors((prevErrors) => ({ ...prevErrors, [fieldName]: "" }));
    } catch (error) {
      setFieldErrors((prevErrors) => ({
        ...prevErrors,
        [fieldName]: error.message,
      }));
    }
  };

  const handle = {
    onChangeField: async (name, value) => {
      let isValid = true;

      if (value === "") {
        setAllValue({ ...allValues, [name]: "" });
      }

      // if (name === "lender") {
      //   isValid = regex.alphaNumericAndSpecificChar.test(value) && value?.length <= 50;
      // }

      if (!value) isValid = false

      if (!isValid) {
        await validateField(name, value);
        return;
      }

      setAllValue({ ...allValues, [name]: value });
      await validateField(name, value);
    },
    blurFn: (fieldName, value) => {
      try {
        let fieldErr = fieldErrors[fieldName];

        // if (fieldName === "lender") {
        //   let isValid = regex.nameWithNumberRegex.test(value);
        //   if (isValid) {
        //     fieldErr = "";
        //   } else {
        //     fieldErr = "Only letters and ' - characters allowed.";
        //   }
        // }

        if (fieldErr === "" && value && value !== lender?.[fieldName]) {
          // For update single field in updates
          updateLenderArrayFields({
            lenderId,
            [fieldName]: value,
          });
        }
      } catch (error) {
        const newErrors = {};
        error.inner.forEach((validationError) => {
          newErrors[validationError.path] = validationError.message;
        });
        setFieldErrors(newErrors);
      }
    },
    handleDateChange: (value) => {
      let date = "";
      if (value) {
        date = moment(value).format("DD MMM yyyy");
      }

      editLender({
        lenderId,
        body: {
          ...Object.fromEntries(Object.entries(lender).filter(([key]) => key !== "_id" && key !== "productTiers")),
          date,
        },
      });
    },
    handleLogoUrlChange: (value) => {
      editLender({
        lenderId,
        body: {
          ...Object.fromEntries(Object.entries(lender).filter(([key]) => key !== "_id" && key !== "productTiers")),
          logoURL: value,
        },
      });
    }
  }


  return (
    <Grid
      container
      xs={12}
      style={{
        margin: "0 0 30px",
        padding: "0 0 20px",
        borderBottom: "1px solid rgba(0,0,0,0.12)",
      }}
    >
      <Grid container xs={3} alignItems="start" direction="column">
        <Stack direction="row" style={{ marginBottom: "10px" }}>
          <DirectionsCarIcon style={{ marginRight: "10px" }} />
          <Typography>Basic Details</Typography>
        </Stack>
      </Grid>
      <Grid
        container
        xs={9}
        spacing={2}
        style={{
          padding: "0 0 0 15px",
        }}
      >
        <Grid container spacing={1} xs={9} direction="row" style={{ margin: "0px 0px 7px 0px" }}>
          <Grid item xs={4}>
            <TextField
              fullWidth
              size="small"
              label="Name"
              // disabled
              variant="filled"
              type="text"
              name="lender"
              error={fieldErrors?.lender}
              helperText={fieldErrors?.lender}
              value={allValues?.lender} //lender?.lender
              onChange={(e) => handle.onChangeField("lender", e.target.value)}
              onBlur={(e) => handle.blurFn("lender", e.target.value)}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              fullWidth
              size="small"
              label="Application Type"
              disabled
              variant="filled"
              type="text"
              name="type"
              value={lender?.type?.charAt(0).toUpperCase() + lender?.type?.slice(1)}
            />
          </Grid>
          <Grid item xs={4}>
            <DatePicker
              selected={lender?.date}
              onChange={(date) => {
                if (date === null || moment(date).isValid()) {
                  handle.handleDateChange(date);
                }
              }}
              dateFormat="d MMM yyyy"
              isClearable
              name="date"
              customInput={<TextField label="Date Updated" size="small" style={{ height: "100%" }} />}
            />
          </Grid>
          <Grid item xs={8}>
            <TextField
              fullWidth
              onChange={(e) => setLogoUrlValue(e.target.value)}
              size="small"
              label="Logo URL"
              variant="filled"
              type="text"
              name="type"
              value={logoUrlValue || ""}
              onBlur={() => handle.handleLogoUrlChange(logoUrlValue)}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
});

export default BasicDetailSection;
